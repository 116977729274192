<template>
  <div id="apps">
   <div class="wuliu clear">
     <div class="heads clear">
       <div class="hea clear">
         <img src="../assets/timg.jpeg"/>
         <div class="rr">
           <p>味多美面包&nbsp;&nbsp;&nbsp;&nbsp;订单编号：111111111111111111111</p>
           <span>2020-12-01 10:21:21</span>
         </div>
       </div>
        <p class="pp">中通快递：781509895656569</p>
     </div>
     <div class="oli">
       <ul>
         <li v-for="(x,i) in logisticsList" :class="{'on':x.acri}">
           <p v-html="x.message"></p>
           <font>{{x.messageDate}}</font>
         </li>
       </ul>
     </div>
   </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components:{
  },
   data () {
      return {
        logisticsList:[
        {
          message: '1包裹已签收，有疑问请致电（<span>18058826910、0316-30031678、137804930293</span>）感谢您使用申通快递，期待再次为您服务',
          messageDate: '2016-03-10 18:07:15',
          acri:true
        },
        {
          message: '1包裹已签收，有疑问请致电（<span>18058826910、0316-30031678、137804930293</span>）',
          messageDate: '2016-03-10 18:07:15',
          acri:false
        },
        {
          message: '北京中转中心---北京燕郊分公司！',
          messageDate: '2016-03-10 18:07:15',
           acri:false
        },
        {
          message: '北京中转中心---北京燕郊分公司！',
          messageDate: '2017-03-10 18:07:15',
           acri:false
        },
        {
          message: '四川中转中心---四川分公司！',
          messageDate: '2018-03-10 18:07:15',
           acri:false
        },
        {
          message: '成都中转中心---成都公司！',
          messageDate: '2019-03-10 18:07:15',
           acri:false
        },
        {
          message: '深圳中转中心---深圳公司！',
          messageDate: '2020-03-10 18:07:15',
           acri:false
        },
        {
          message: '广东中转中心---广东公司！',
          messageDate: '2011-03-10 18:07:15',
           acri:false
        },
        {
          message: '四川中转中心---四川公司！',
          messageDate: '2013-03-10 18:07:15',
           acri:false
        }
      ]
      }
  },
  methods:{
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  li{list-style-type: none;}
  body{background:#fff;height: 100vh;}
  .wuliu .heads{width:100%;border-bottom:solid 1px #E5E5E5;margin-bottom:7%;}
  .wuliu .heads .pp,.wuliu .heads .hea p,.wuliu .heads .hea span{color: rgba(16, 16, 16, 100);
font-size: 12px;display: block;
font-family: 方正准圆-标准;}
.wuliu .heads .pp{margin:0 0 4% 3%;}
.wuliu .heads .hea .rr{margin-top: 3.5%;}
.wuliu .heads .hea span{color: rgba(16, 16, 16, 100);margin-top:2%;}
.wuliu .heads .hea img{width:12%;display: inline-block;float:left;margin:3%;}
.wuliu .oli ul li{border-left:solid 1px #E5E5E5;width:84%;margin-left:7%;font-size:13px;color:#A0A0A0;padding:0% 0 11% 6%;position: relative;}
.wuliu .oli ul li font{font-size:12px;}
.wuliu .oli ul li.on{color:#45B425;}
.wuliu .oli ul li p{margin-top:-7px;}
.wuliu .oli ul li p span{color:#466DBB;}
.wuliu .oli ul li:before{content:'';width:9px;height:9px;border-radius:9px;background:#DADADA;position: absolute;left:-5px;top:0px;}
.wuliu .oli ul li.on::before{content:'';width:9px;height:9px;border-radius:9px;background:#45B425;position: absolute;left:-8px;top:0px;border:solid 3px #A2D891;}
</style>
